import React , { memo, useRef, useEffect, useState } from 'react'
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import '../../../styles/blogArticle.scss'
import { useDispatch } from 'react-redux';
import moment from 'moment'
import NoImage from '../../../images/no-image.png'

const BlogArticle = (props) => {
    const {
        storeAction
    } = props
    const NavigationOwl = useRef();
    const Dispatch = useDispatch()
    const [data,setData] = useState([])
    const handleOwlNav = (e, ops) => {
        if(e) e.preventDefault()
        if (ops === 'prev') {
            NavigationOwl.current.prev(500)
        } else if (ops === 'next') {
            NavigationOwl.current.next(500)
        }
    }

    const goToLink = (link) => {
        if (link) {
            const win = window.open(link, "_blank");
            win.focus();
        }
    }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    useEffect(()=>{
        Dispatch(storeAction({
            id_status: 1,
            is_active_elive: 1,
            limit: 15,
            offset: false,
        },(status, data)=>{
            if (status) {
                if (data) {
                    let dataFilter = data?.filter(session=>{
                        if (session?.berita_info?.is_active_elive === '1') {
                            return session
                        }
                    })
                    setData(
                        shuffle(dataFilter)?.slice(0,15).map(session=> ({...session?.berita_info }))
                    )
                }
            }
        }))
    },[])

    const handleLimitString = (str, limit) => {
        if (str) {
            if (str.length > limit) {
                return `${str.slice(0,limit)} ...`
            }
        }
        return str
    }

    return (
        <section className="py-4">
            <div className="container">
                <div className={data.length ? "row" : "row d-none"}>
                    <div className="col-lg-12 mb-2">
                        <div className="headContent">
                            <div className="titleHeadOwl">
                                <h2>Articles &nbsp;<span>Featured</span></h2>
                            </div>
                            <div className="customNavigation">
                                <span className="viewAll" onClick={_=> goToLink(`https://blog.livelifeindo.com`)}>View all</span>
                                <span className="btn gray pplPrev" onClick={e=> handleOwlNav(e, 'prev') }>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                                <span className="btn gray pplNext" onClick={e=> handleOwlNav(e, 'next') }>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel
                        key={data}
                        id="blog-article"
                        className="owl-carousel"
                        responsiveClass
                        loop={true}
                        responsive={
                            {
                                0:{items:1,nav:false},
                                600:{items:2,nav:false},
                                1000:{items:3,nav:true,loop:false}
                            }
                        }
                        margin={10}
                        ref={NavigationOwl}
                    >
                        {
                            data?.map((blog,index)=>(
                                <div className="item ourBlog" key={(index).toString()}>
                                    <a href={`https://blog.livelifeindo.com/detail/${blog?.judul_seo}`} target="_blank" title={blog?.judul}>
                                        <img
                                            src={`https://blog.livelifeindo.com/assets/foto_berita/${blog.gambar}`}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
                                        />
                                        <div className="itemTimeBlog">
                                            <p>
                                                <FontAwesomeIcon icon={faClock} /> { moment(new Date(blog.tanggal)).format('dddd, DD MMM YYYY') }
                                            </p>
                                        </div>
                                    </a>
                                    <div className="itemBlog">
                                        <a href={`https://blog.livelifeindo.com/detail/${blog?.judul_seo}`} target="_blank" title={blog?.judul}><h4>{blog?.judul}</h4></a>
                                        <div dangerouslySetInnerHTML={{ __html: handleLimitString(blog?.isi_berita, 150) }} />
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                </div>  			
            </div>
        </section>
    )
};

export default memo(BlogArticle);