import React , { memo } from 'react'
import BannerRecording from '../../../components/BannerRecording'

const goToZoom = (link) => {
    if (link) {
        const win = window.open(link, "_blank");
        win.focus();
    }
}

const checkingCondition = (event) => {
    let event_info = event?.event_info
    if (event_info?.is_main_stage_elive === '1') {
        if (event_info?.zoom_link_elive) {
            return 'zoom'
        }
        if (event_info?.url_event_video) {
            return 'record'
        }
    }
}

const BannerZoom = (props) => {
    const {
        eventState
    } = props
    let checking = checkingCondition(eventState)
    
    return (
        <div className="container">
            {
                checking === 'zoom' ?
                <div className="headWatch" >
                    <div className="d-flex">
                        <div className="headTimeSession">
                            <p style={{ color: 'red' }}>
                                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem necessitatibus debitis iste facere. Soluta corrupti, laborum voluptatum porro non dicta sint quas deleniti consequatur nihil cumque facilis, nisi nemo! Dolore. */}
                            </p>
                        </div>
                        <div className="watchZoom">								
                            <div className="customNavigation" style={{ width: '7rem' }}>
                                <div
                                    className={!(eventState?.event_info?.is_main_stage_elive) ? "zoomActionDisabled" : 'zoomAction'}
                                    onClick={_=> goToZoom(eventState?.event_info?.zoom_link_elive)}
                                >
                                    {/* <!-- if session close replace button go to feedback--> */}
                                    <span style={{display: 'initial'}}>
                                        <i className="fas fa-video"></i> Go to Zoom 
                                    </span>
                                    {/* <!-- feedback
                                    <a href="">
                                        <i className="far fa-keyboard"></i> Leave Feedback 
                                    </a>
                                    --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                checking === 'record' &&
                <div className="ratio ratio-16x9">
                    <BannerRecording eventState={eventState} />
                </div>
            }
        </div>
    )
};

export default memo(BannerZoom);