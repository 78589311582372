import React, { memo } from 'react';
import NoImage from '../images/no-image.png'
import { convertYoutubeLink, checkingBannerStatus } from 'helpers'

const BannerRecording = memo(({ eventState }) =>{
    
    if (eventState) {

        return (
            convertYoutubeLink(eventState?.event_info, 'url_event_video') ?
            <iframe width="560" height="315" src={convertYoutubeLink(eventState?.event_info, 'url_event_video')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
            :
            <img
                src={eventState?.event_info?.event_photo}
                alt=""
                draggable={false}
                onError={(e)=>{e.target.onerror = null; e.target.src=NoImage}}
            />
        )
        
    }
});

export default BannerRecording;