const bootstrap = require('bootstrap');
import React,{ useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import '../../styles/homepage.scss'
import '../../styles/gradients.scss'
import '../../styles/customMainStage.scss'
import NavbarTop from '../../components/TopNavbar'
import BottomNavbar from '../../components/BottomNavbar'
import BannerEvent from '../../components/BannerEvent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import SpeakersModal from '../../components/SpeakersModal'
import SessionModal from '../../components/SessionModal'
import { getPollingAnswersByUser, createPollingAnswer, getBlogArticles } from '../../store/actions/event';
import { useCookies } from 'react-cookie';
import { useToasts } from 'react-toast-notifications';
import SessionList from './Component/Session'
import FeedbackList from './Component/Feedback'
import UserList from './Component/UserList';
import Sponsor from './Component/Sponsor';
import BlogArticle from './Component/BlogArticle';

// IMAGES
import ImageHaris from '../../images/haris.jpg'
import LLLogoSquare from '../../images/ll.jpg'

const Homepage = (props) => {
    const {
        pollList,
        pollAnswer,
        setPollAnswer,
        pollAnswerQuest,
        setPollAnswerQuest,
        pollAnswerQuestTotaly,
        setPollAnswerQuestTotaly,
        setNextPoll
    } = props
    const { addToast } = useToasts()
    const [cookies] = useCookies(['account']);
    const History = useHistory()
    const Dispatch = useDispatch()
    const [modalPerson, setModalPerson] = useState(false);
    const eventState = useSelector(state=> state.eventState.event )
    const [pollAnswerSelect, setPollAnswerSelect] = useState(null);
    const [pollLoading, setPollLoading] = useState(false);
    const [modalEvent, setModalEvent] = useState({
        show: false,
        data: null,
        index: null
    });

    useEffect(()=>{
        $(".chatBtn-1312").click(function(){
            var myAlert =document.getElementById('chatForm');
            var bsAlert = new bootstrap.Toast(myAlert);
            bsAlert.show();
        })
    },[])

    const handlerModalPerson = (e, value) => {
        if(e) e.preventDefault()
        if (value) {
            setModalPerson(value)
        } else {
            setModalPerson(value)
        }
    }

    const handlerModalEvent = (e, value, data, index = null) => {
        if(e) e.preventDefault()
        setModalEvent({
            show: value,
            data,
            index
        })
    }

    const goOnTop = () => {
        window.scroll({
            top: 0, 
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleSubmitPoll = (e,id_survey,id_survey_question) => {
        if (e) e.preventDefault()
        if (pollAnswerSelect && pollAnswerSelect.length !== 0) {
            setPollLoading(true)
            Dispatch(getPollingAnswersByUser({ id_survey, id_user: cookies['account'], id_survey_question }, (status, data)=>{
                if (status) {
                    addToast('Poll Has Been Selected', {
                        appearance: 'warning',
                        autoDismiss: true,
                    })
                    setNextPoll(true)
                } else if (!status) {
                    Dispatch(createPollingAnswer({
                        "id_survey_question" : id_survey_question,
                        "survey_answer" : JSON.stringify(pollAnswerSelect),
                        "id_user" : cookies['account']
                    },(status,data)=>{
                        if (!status) {
                        } else {
                            Dispatch(getPollingAnswersByUser({ id_survey, id_user: null, id_survey_question }, async (status, data)=>{
                                if (status) {
                                    if (data.result) {
                                        if (pollList[0]) {
                                            let questions = JSON.parse(pollList[0].question_choice)
                                            if (questions) {
                                                let questObj = {}
                                                let totalyVote = 0
                                                await questions.map(quest=>{
                                                    questObj[quest] = 0
                                                })
                                                await data.result.forEach(async answer=>{
                                                    if (answer.answer_info) {
                                                        if (answer.answer_info.survey_answer) {
                                                            let answerMe = JSON.parse(answer.answer_info.survey_answer)
                                                            if (answerMe) {
                                                                await answerMe.forEach(ans=>{
                                                                    if ((questObj[ans] !== null)||(questObj[ans] !== undefined)) {
                                                                        questObj[ans] = questObj[ans] +=1
                                                                        totalyVote+=1
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    }
                                                })
                                                let finRest = []
                                                let temp = {}
                                                for (const key in questObj) {
                                                    temp.answer = key
                                                    temp.percentage = String(Math.round((questObj[key] / totalyVote) * 100))
                                                    finRest.push(temp)
                                                    temp = {}
                                                }
                                                setPollAnswerQuest(finRest)
                                                setPollAnswerQuestTotaly(data.result.length)
                                                setPollAnswer(true)
                                                setPollLoading(false)
                                            }
                                        }
                                    }
                                }
                            }))
                        }
                    }))
                }
            }))
        } else {
            addToast('Please Choose answer first', {
                appearance: 'warning',
                autoDismiss: true,
            })
        }
    }

    const handlePollSelect = (type, value) => {
        let pollSelect = pollAnswerSelect
        if (type === 'checkbox') {
            if (pollSelect) {
                let checking = pollSelect.indexOf(value)
                if (checking === -1) {
                    pollSelect.push(value)
                } else {
                    pollSelect.splice(checking, 1);
                }
                setPollAnswerSelect(pollSelect)
            } else if(!pollSelect || pollSelect.length === 0) {
                setPollAnswerSelect([value])
            }
        } else if (type === 'radio') {
            if (pollSelect) {
                let checking = pollSelect.indexOf(value)
                if (checking === -1) {
                    pollSelect = [value]
                } else {
                    pollSelect.splice(checking, 1);
                }
                setPollAnswerSelect(pollSelect)
            } else if(!pollSelect || pollSelect.length === 0) {
                setPollAnswerSelect([value])
            }
        }
    }

    const converterDate = (data) => {
        if (data) {
            let flag = null
            let dateConvertFrom
            let dateConvertTo
            let timeStart
            let timeEnd
            if(data.event_date) {
                flag = true
                dateConvertFrom = data.event_date
                if (data.event_time) {
                    timeStart = data.event_time
                }
                if (data.event_end_time) {
                    timeEnd = data.event_end_time
                }
                if(data.event_end_date) {
                    if (data.event_date !== data.event_end_date) {
                        dateConvertTo = data.event_end_date
                    }
                }
            }
            if (!flag) return null
            return dateConvertTo ? `${dateConvertFrom} | ${timeStart} - ${dateConvertTo} | ${timeEnd}` : `${dateConvertFrom} | ${timeStart} - ${timeEnd}`
        }
    }

    return (
        <div id="homepage-87612">
            <div className="bg-homepage"/>
            <NavbarTop />

            <section className="contentHeader pt-8 pb-4">
                <div className="container">
                    <div className="ratio ratio-21x9">
                        <BannerEvent eventState={eventState} />
                    </div>
                </div>
            </section>

            <section>
                <div className="pt-1">
                    <div className="main-stage mx-auto w-100">
                        <h2 className="fw-bold inline-flex">
                            <div className='text-lg'>Watch Now on&nbsp;</div>
                            <div className="badge bg-light text-primary join-btn text-xl" onClick={e=> History.push('/main-stage')}>
                                Main Stage
                            </div>
                        </h2>
                        <div className='pt-2'>
                            <p className="opacity-75 rounded">{converterDate(eventState?.event_info)}</p>
                        </div>
                    </div>
                </div>
            </section>

            <hr/>

            <FeedbackList
                eventState={eventState?.event_info}
            />

            {
                pollList[0] &&
                <section className="pt-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className="headContent">
                                <div className="titleHead">
                                    <h2>Polling &nbsp;<span>Featured</span></h2>
                                </div>
                                <div className="customViewAll">
                                    <div className="pollTop">
                                        {
                                            pollAnswerQuestTotaly &&
                                            <span className="pollBtn">{pollAnswerQuestTotaly} {pollAnswerQuestTotaly >= 1 ? 'votes' : 'vote'}</span>
                                        }
                                        {/* <!-- jika polling telah selesai ganti className pollBtnDisable */}
                                        {/* <span className="pollBtnDisable">Poll ended</span> */}
                                        {/* --> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mb-2">
                            <div className="borderContent mb-3 poll">
                                <div className="paddingContent sidePoll">
                                    {
                                        !pollAnswer && 
                                        <form>
                                            <div className="pollQuestion">
                                                <p>{ pollList[0] && pollList[0].survey_question }</p>
                                            </div>
                                            <div className="pollAnswer">
                                                {
                                                    pollList[0] && JSON.parse(pollList[0].question_choice) &&
                                                    JSON.parse(pollList[0].question_choice).map((answer,i)=>(
                                                        <div className="form-check" key={i.toString()}>
                                                            <input className="form-check-input" onChange={e=> handlePollSelect((pollList[0].question_type).toLowerCase() === 'option' ? 'radio' : (pollList[0].question_type).toLowerCase(),e.target.value)} type={(pollList[0].question_type).toLowerCase() === 'option' ? 'radio' : (pollList[0].question_type).toLowerCase()} id={i} name="question" value={answer}/>
                                                            <label className="form-check-label" htmlFor={i}>
                                                                {answer}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                                <div className="pollSubmit">
                                                    {
                                                        pollLoading ?
                                                        <button style={{width: 55}}>
                                                            <div className="spinner-border spinner-custom-btn-poll" />
                                                        </button>
                                                        :
                                                        <button type="submit" onClick={e=>handleSubmitPoll(e,pollList[0]?.id_survey, pollList[0]?.id_survey_question)}>Submit</button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    }
                                    {/* <!--jika klik submit keluarkan hasil polling--> */}
                                    {
                                        pollAnswer &&
                                        <>
                                            <div className="pollQuestion">
                                                <p>{ pollList[0] && pollList[0].survey_question }</p>
                                            </div>
                                            <div className="pollAnswer">
                                            {
                                                pollAnswerQuest &&
                                                pollAnswerQuest.map((answer,i)=>(
                                                    <div className="progress" key={i.toString()}>
                                                        <div className="progress-bar" role="progressbar"
                                                            style={{
                                                                width: answer.percentage >= 100 ? "100%" : `${answer.percentage}%`
                                                            }}
                                                            aria-valuenow={answer.percentage >= 100 ? 100 : answer.percentage}
                                                            aria-valuemin="0" aria-valuemax="100"
                                                        >
                                                        </div>
                                                        <span className="listPoll">{answer.answer}</span>
                                                        <span
                                                            className="listPoll"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'end',
                                                                width: '100%',
                                                                padding: '0px 20px',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {answer.percentage >= 100 ? 100 : answer.percentage}%
                                                        </span>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </section>
            }

            <SessionList
                handlerModalEvent={handlerModalEvent}
            />

            <UserList
            />

            <BlogArticle
                storeAction={getBlogArticles}
            />

            <Sponsor
            />
            
            <BottomNavbar />

            <Modal
                show={modalPerson}
                onHide={e => handlerModalPerson(e,false)}
                size="lg"
                centered
                backdropClassName="modalBackdropPerson"
                className="modaloverlayPerson modalDialogPerson fade zoom-in"
                data-easein="bounceLeftIn"
            >
                <SpeakersModal onHide={e => handlerModalPerson(e,false)}/>
            </Modal>

            <Modal
                show={modalEvent.show}
                onHide={e => handlerModalEvent(e,false, null)}
                size="lg"
                centered
                backdropClassName="modalBackdropEvent"
                className="modalDialogEvent fade zoom-in"
                data-easein="bounceLeftIn"
                scrollable
            >
                <SessionModal
                    onHide={e => handlerModalEvent(e,false, null)}
                    session={{ ...modalEvent.data, index: modalEvent.index}}
                    handlerModalPerson={e => handlerModalPerson(e,true)}
                    goOnTop={goOnTop}
                    History={History}
                />
            </Modal>

            <div id="chatForm" role="alert" aria-live="assertive" aria-atomic="true" className="toast" data-bs-autohide="false">
                <div className="toast-header">
                    <img src={ImageHaris} className="me-2" alt="Haris Riswandi"/>
                    <h5 className="me-auto"><a>Haris Riswandi</a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                </div>
                <div className="toast-body">
                    <div className="chatMessageWrapper">
                        <div className="chatUserAvatar">
                            <img src={LLLogoSquare} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                    <div className="chatMessageWrapper chatRight">
                        <div className="chatUserAvatar">
                            <img src={ImageHaris} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                    <div className="chatMessageWrapper">
                        <div className="chatUserAvatar">
                            <img src={LLLogoSquare} alt="LiveLife"/>
                        </div>
                        <ul className="chatMessage">
                            <li>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, eum? </p>
                            </li>
                            <li>
                                <p> Lorem ipsum dolor sit amet.<span className="chatMessageTime">13:38</span> </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="toast-footer">
                    <form>
                        <div className="row g-2">
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="liveChat" placeholder="Type a message" autoComplete="off" />
                            </div>
                            <div className="col-sm text-end sendMessage">
                                <button type="submit" className="BtnCircle">
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default Homepage